<template>
  <div>
    <p>Выберите период:</p>
    <div>
      <div>
        <label>
          <input type="radio" v-model="period" value="O">
          Одна неделя</label>
      </div>
      <div>
        <label>
          <input type="radio" v-model="period" value="Т">
          Две недели</label>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "rangePeriod",
    data(){
      return {
        period:'O'
      }
    }
  }
</script>

<style scoped>

</style>
